<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit Termin" :active="isActive" v-on:update:active="emitModalIsActive">

      <div :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. IPL</label>
            <vs-input class="w-full" :value="data.no_ipl" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Pengelola</label>
            <vs-input class="w-full" :value="data.nama_pengelola" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No Unit</label>
            <vs-input class="w-full" :value="`${data.kode_proyek_unit} | ${data.no_unit}`" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Tgl. Due *</label>
            <flat-pickr class="w-full" v-model="data.tgl_due"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider class="mt-base">Item Pekerjaan IPL</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex space-x-3">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="modalItemIpl.active = true" class="py-2 px-3">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vs-table :data="data.pekerjaans" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Aksi</vs-th>
            <vs-th class="whitespace-no-wrap">Nama Item IPL</vs-th>
            <vs-th class="whitespace-no-wrap">Tarif *</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl.Mulai</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl.Akhir</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td>{{ item.nama_item_ipl }}</vs-td>
              <vs-td><v-money class="w-48" v-model="item.tarif"/></vs-td>
              <vs-td><flat-pickr class="w-full" v-model="item.tgl_awal"></flat-pickr></vs-td>
              <vs-td><flat-pickr class="w-full" v-model="item.tgl_akhir"></flat-pickr></vs-td>
              <vs-td><vs-input class="w-full" type="text" v-model="item.keterangan"/></vs-td>
            </vs-tr>

            <!--footer-->
            <vs-tr class="text-sm" v-if="data.length > 0">
              <vs-td class="font-bold" colspan="2">GRAND TOTAL</vs-td>
              <vs-td class="text-left font-bold"><v-money class="w-48" :value="grandTotal" disabled/></vs-td>
              <vs-td class="font-bold" colspan="3"></vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="isLoading" @click="save">
                  <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!isLoading">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modal item ipl-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Item IPL"
                :active="modalItemIpl.active"
                v-on:update:active="modalItemIpl.active = $event">
        <ItemIpl v-if="isActive" :selectable="true" multi-select :disable-ids="itemIplIds" :externalFilter="filterItemIpl" @selected="onSelectedModalItemIpl"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import IplRepository from '@/repositories/estate/ipl-repository'
import ItemIpl from '@/views/pages/master/item-ipl/ItemIpl'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'TerminEdit',
  props: ['isActive', 'idIpl'],
  components: {
    ValidationErrors,
    ItemIpl,
    VMoney,
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      isLoadingInitData: false,
      errors: null,
      modalItemIpl: {
        active: false
      },
      data: {
        pekerjaans: [],
        deletedIds: []
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  computed: {
    filterItemIpl () {
      return { id_properti_unit: this.data.id_properti_unit }
    },
    grandTotal () {
      return _.sumBy(this.data.pekerjaans, item => parseFloat(item.tarif || '0'))
    },
    itemIplIds () {
      return _.map(this.data.pekerjaans, item => item.id_item_ipl)
    }
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true
      IplRepository.showTermin(this.idIpl)
        .then(response => {
          this.data = { ...this.data, ...response.data.data }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      IplRepository.updateTermin(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    addRow (item) {
      const row = {
        uuid: uuid(),
        id_item_ipl: item.id,
        nama_item_ipl: item.nama,
        tarif: item.tarif
      }
      this.data.pekerjaans.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.pekerjaans, item => item.uuid === uuid)
      const item = this.data.pekerjaans[index]
      if (item.id) {
        this.data.deletedIds.push(item.id)
      }
      this.data.pekerjaans.splice(index, 1)
    },

    buildParams () {
      return {
        id: this.data.id,
        tgl_due: this.data.tgl_due,
        keterangan: this.data.keterangan,
        deletedIds: this.data.deletedIds,
        pekerjaans: _.map(this.data.pekerjaans, item => {
          return {
            id: item.id,
            id_item_ipl: item.id_item_ipl,
            tarif: item.tarif,
            tgl_awal: item.tgl_awal,
            tgl_akhir: item.tgl_akhir,
            keterangan: item.keterangan
          }
        })
      }
    },

    onSelectedModalItemIpl (items) {
      _.each(items, item => this.addRow(item))
      this.modalItemIpl.active = false
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
